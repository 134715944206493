<template>
  <v-card v-if="plan" class="pa-4 ma-4">
    <v-card-actions>
      <v-text-field
          class="pr-2"
          label="Plan ID"
          v-model="plan.id"
          disabled
      />
      <v-text-field
          class="pr-2"
          label="Plan name"
          v-model="plan.name"
      />
      <v-text-field
          class="pr-2"
          label="Stream"
          v-model="plan.stream"
      />
      <v-checkbox
          class="pr-2"
          label="A la Carte Pricing"
          v-model="plan.alacarte"
      />
      <v-text-field
          class="pr-2"
          label="Per meal"
          type="number"
          v-model="plan.per_meal"
      />
      <v-text-field
          class="pr-2"
          label="Large Price Surcharge"
          type="number"
          v-model="plan.large_price"
      />

      <v-btn outlined @click="addRange(plan)">Add Range</v-btn>
      <v-btn outlined @click="copyPlan(plan)">Copy Plan</v-btn>
    </v-card-actions>
    <v-card-text>
      <v-data-table
          disable-pagination
          hide-default-footer
          :items="plan.ranges"
          :headers="headersRange"
      >
        <template v-slot:item.description="{item }">
          <v-text-field v-model="item.description"/>
        </template>
        <template v-slot:item.min="{item }">
          <v-text-field type="number" v-model="item.min"/>
        </template>
        <template v-slot:item.max="{item }">
          <v-text-field type="number" v-model="item.max"/>
        </template>
        <template v-slot:item.price="{item }">
          <v-text-field type="number" v-model="item.price"/>
        </template>
        <template v-slot:item.free_shipping="{item }">
          <v-checkbox v-model="item.free_shipping"/>
        </template>
        <template v-slot:item.action="{item: sub}">
          <v-btn @click="deleteRange(item,sub)" icon color="red">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="$router.push({name:'Plans'})" outlined>Cancel</v-btn>
      <v-spacer/>
      <v-btn @click="savePlan" outlined>Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "PlanEdit",
  mounted() {
    console.log('plan edit', this.id, this.$route.params);
    return this.fetchData();
  },
  props: {
    id: {type: String, default: null, required: true}
  },
  watch: {
    'id': 'fetchData'
  },
  data() {
    return {
      plan: null,
      headersRange: 'description, min, max, price, free_shipping, action'
          .split(', ')
          .map(s => ({text: s, value: s})),
      plans: null
    }
  },
  methods: {
    ...mapActions(['fetchPlans', 'updatePlan']),
    deleteRange(item, subItem) {
      console.log('need to implement delete', {item, subItem});
      const index = item.ranges.findIndex(i => i === subItem);
      console.log('delete', index)
      item.ranges.splice(index, 1);
    },
    addRange(item) {
      if (!item.ranges) {
        this.$set(item, 'ranges', []);
      }
      item.ranges.push({});
    },
    async copyPlan(item) {
      const newPlanId = prompt('Enter the name of the new plan');
      if (newPlanId) {
        if (this.plans[newPlanId]) {
          alert('a plan with that name already exists');
          return;
        }
        await this.updatePlan({id: newPlanId, update: item});
        return this.$router.push({params: {id: newPlanId}});
      }
    },
    async savePlan() {
      const {id, plan} = this;
      await this.updatePlan({id, update: plan});
      window.alert('saved');
    },
    fetchData() {
      return this
          .fetchPlans()
          .then(plans => {
            this.plans = plans;
            this.plan = {...plans[this.id], id: this.id}
          });
    }
  },

}
</script>

<style scoped>

</style>;